/*
 * Dark Colors
 * -------------------------------------------
 */

/*body {
   --ion-color-primary: #ffd300;
  --ion-color-primary-rgb: 255,211,0;
  --ion-color-primary-contrast: #000;
  --ion-color-primary-contrast-rgb: 0,0,0;
  --ion-color-primary-shade: #e0bb2e;
  --ion-color-primary-tint: #ffd948;

 }*/

@media (prefers-color-scheme: dark) {
  //si dejo esto vacío en el variables.scss el Dark Mode del device fija estos estilos. Es decir, ninguno xD
}

body.dark {
  --ion-color-primary: #ffd300;
  --ion-color-primary-rgb: 255, 211, 0;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #e0bb2e;
  --ion-color-primary-tint: #ffd948;
  --ion-color-success: #ffd300;
  --ion-color-success-rgb: 255, 211, 0;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #e0bb2e;
  --ion-color-success-tint: #ffd948;
  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;
  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;
  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;
  --ion-color-medium: #b5b5b5;
  --ion-color-medium-rgb: 181, 181, 181;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  --ion-color-chat-bubble-ours: #ffd534;
  --ion-color-chat-bubble-other: #b3b3b3;
}

/*
 * iOS Dark Theme
 * -------------------------------------------
 */

.ios body.dark {
  --ion-background-color: #272727;
  --ion-background-color-transparent: #27272783;
  --ion-background-color-rgb: 39, 39, 39;
  --ios-color-base: #272727;
  --ios-color-base-rgb: 39, 39, 39;
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;
  --ion-text-color-black: #000000;
  --ion-text-color-black-rgb: 0, 0, 0;
  --ion-text-color-white: #ffffff;
  --ion-text-color-white-rgb: 255, 255, 255;
  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;
  --ion-toolbar-background: #0d0d0d;
  --ion-item-background: #272727;
  --ion-item-background-activated: #323232;
}

/*
 * Material Design Dark Theme
 * -------------------------------------------
 */

.md body.dark {
  --ion-background-color: #272727;
  --ion-background-color-transparent: #27272783;
  --ion-background-color-rgb: 39, 39, 39;
  --ion-color-base: #272727;
  --ion-color-base-rgb: 39, 39, 39;
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;
  --ion-text-color-black: #000000;
  --ion-text-color-black-rgb: 0, 0, 0;
  --ion-text-color-white: #ffffff;
  --ion-text-color-white-rgb: 255, 255, 255;
  --ion-border-color: #222222;
  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;
  --ion-item-background: #272727;
  --ion-item-background-activated: #323232;
}

:root {
  --ion-color-primary: #272727;
  --ion-color-primary-rgb: 39, 39, 39;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #202020;
  --ion-color-primary-tint: #343434;
  --ion-color-success: #272727;
  --ion-color-success-rgb: 39, 39, 39;
  --ion-color-success-contrast: #fff;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #202020;
  --ion-color-success-tint: #343434;
  --ion-color-warning: #ffca00;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;
  --ion-color-transparent: #00000000;
  --ion-color-transparent-rgb: 0, 0, 0;
  --ion-color-transparent-contrast: #00000000;
  --ion-color-transparent-contrast-rgb: 0, 0, 0;
  --ion-color-transparent-shade: #00000000;
  --ion-color-transparent-tint: #00000000;
  --ion-background-color: #ffffff !important;
  --ion-background-color-transparent: #ffffff7a;
  --ion-background-color-rgb: 255, 255, 255 !important;
  --ion-color-base: #ffffff i !important;
  --ion-color-base-rgb: 255, 255, 255 !important;
  --ion-text-color: #000000;
  --ion-text-color-rgb: 0, 0, 0;
  --ion-text-color-black: #000000;
  --ion-text-color-black-rgb: 0, 0, 0;
  --ion-text-color-white: #ffffff;
  --ion-text-color-white-rgb: 255, 255, 255;
  --ion-color-step-50: #f2f2f2;
  --ion-color-step-100: #e6e6e6;
  --ion-color-step-150: #d9d9d9;
  --ion-color-step-200: #cccccc;
  --ion-color-step-250: #bfbfbf;
  --ion-color-step-300: #b3b3b3;
  --ion-color-step-350: #a6a6a6;
  --ion-color-step-400: #999999;
  --ion-color-step-450: #8c8c8c;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #737373;
  --ion-color-step-600: #666666;
  --ion-color-step-650: #595959;
  --ion-color-step-700: #4d4d4d;
  --ion-color-step-750: #404040;
  --ion-color-step-800: #333333;
  --ion-color-step-850: #262626;
  --ion-color-step-900: #191919;
  --ion-color-step-950: #0d0d0d;
  --ion-backdrop-opacity: 0.25;

  --ion-color-chat-bubble-ours: #ffca00;
  --ion-color-chat-bubble-other: #b3b3b3;
}

.ion-color-transparent {
  --ion-color-base: var(--ion-color-transparent);
  --ion-color-base-rgb: var(--ion-color-transparent-rgb);
  --ion-color-contrast: var(--ion-color-transparent-contrast);
  --ion-color-contrast-rgb: var(--ion-color-transparent-contrast-rgb);
  --ion-color-shade: var(--ion-color-transparent-shade);
  --ion-color-tint: var(--ion-color-transparent-tint);
}

@font-face {
  font-family: 'BloggerSans';
  src: url('../assets/fonts/BloggerSans.eot') format('embedded-opentype'),
    url('../assets/fonts/BloggerSans.woff') format('woff'), url('../assets/fonts/BloggerSans.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: 'BloggerSans' !important;
}
