@keyframes easeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes easeInHalf {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.5;
    }
}


@keyframes appearFromBot {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }

}

@-ms-keyframes spin {
    10% {
        -ms-transform: rotate(0deg);
    }
    35% {
        -ms-transform: rotate(240deg);
    }
    100% {
        -ms-transform: rotate(360deg);
    }
  }
  
  @-moz-keyframes spin {
    10% {
        -moz-transform: rotate(0deg);
    }
    35% {
        -moz-transform: rotate(240deg);
    }
    100% {
        -moz-transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes spin {
    10% {
        -webkit-transform: rotate(0deg);
    }
    35% {
        -webkit-transform: rotate(240deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    10% {
        transform: rotate(0deg);
    }
    35% {
        transform: rotate(240deg);
    }
    100% {
        transform: rotate(360deg);
    }
  }

@keyframes fill {
    0% {
        stroke-dashoffset: 0;
    }
    100% {
        stroke-dashoffset: 1;
    }
}