/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */

@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */

@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';
@import './theme/animations.css';

@import 'swiper/scss';
@import 'swiper/scss/navigation';
// @import '~swiper/scss/bundle';

:root {
  --swiper-pagination-color: #ffd300 !important;
  --swiper-navigation-color: #ffd300 !important;
}

.safeArea {
  margin-bottom: constant(safe-area-inset-bottom) !important;
  margin-bottom: env(safe-area-inset-bottom) !important;
}

.multiLineAlert {
  white-space: pre-wrap;
}

// .ion-router-desktop {
//   width: 1024px;
//   margin: auto;
// }

ion-header {
  max-width: 100%;
  align-self: center;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.ion-router-desktop ion-header {
  width: 1024px;
}

.ion-router-desktop ion-content {
  width: 100% !important;
  > * {
    width: 1024px;
    display: block;
    margin: auto !important;
  }
}

app-reader-slider {
  display: inherit !important;
}
// app-serie-list {
//   display: block;
// }

// ion-grid {
//   width: 1024px;
//   max-width: 100%;
// }

ion-segment {
  // width: 1024px;
  max-width: 100%;
  align-self: center;
  display: grid !important;
  // margin: 0 auto;
}

ion-tab-bar.desktop {
  border: none;
}

ion-footer {
  // width: 1024px;
  max-width: 100%;
  align-self: center;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

ion-popover {
  .popover-wrapper {
    .popover-content {
      width: 90% !important;
      min-width: 200px !important;
    }
  }
}

ion-popover.optionsPopover {
  --width: 325px !important;
  --max-width: 350px !important;
}

ion-popover.settingsPopover {
  --min-width: 270px !important;
  --max-width: 300px !important;
}

ion-popover.hideLinksPopover {
  --width: 300px !important;
}

super-tab-button ion-label {
  --st-label-text-transform: none;
}

ion-content.segments {
  // width: 1024px;
  max-width: 100%;
  align-self: center;
  margin: 0 auto;
}

.change-password-modal .modal-wrapper {
  //height: 400px;
  width: 100%;
  height: 100%;
}

.hide-genres-modal .modal-wrapper {
  //height: 400px;
  width: 100%;
  height: 100%;
}

.forgot-password-modal .modal-wrapper {
  //height: 270px;
  width: 100%;
  height: 100%;
}

.account-modal .modal-wrapper {
  //height: 440px;
  width: 100%;
  height: 100%;
}

.edit-profile-modal .modal-wrapper {
  //height: 440px;
  width: 100%;
  height: 100%;
}

.cancel-subscription-modal .modal-wrapper {
  //height: 500px;
  width: 100%;
  height: 100%;
}

.subscribe-modal .modal-wrapper {
  //height: 500px;
  width: 100%;
  height: 100%;
}

.chapter-access-modal .modal-wrapper {
  //height: 300px;
  //width: 200px;
  width: 100%;
  height: 100%;
}

.shop-modal .modal-wrapper {
  //height: 500px;
  width: 100%;
  height: 100%;
}

.about-us-modal .modal-wrapper {
  width: 100%;
  height: 100%;
}

.settings-modal .modal-wrapper {
  //height: 310px;
  width: 100%;
  height: 100%;
}

.contact-modal .modal-wrapper {
  //--height: 500px;
  width: 100%;
  height: 100%;
}

.faq-modal .modal-wrapper {
  //--height: 660px;
  width: 100%;
  height: 100%;
}

.rss-modal .modal-wrapper {
  //height: 310px;
  width: 100%;
  height: 100%;
}

.image-preview-modal .modal-wrapper {
  width: 100%;
  height: 100%;
}

.camera-preview-modal .modal-wrapper {
  width: 100%;
  height: 100%;
}

.session-devices-modal .modal-wrapper {
  width: 100%;
  height: 100%;
}

.annotations-alert .alert-wrapper {
  min-width: 400px !important;
}

.special-textarea {
  height: 300px;
}

.special-textarea-mobile {
  height: 150px;
}

.annotations-alert-mobile .alert-wrapper {
  min-width: 300px !important;
}

ion-item {
  --border-color: var(--ion-color-step-300);
  --background: var(--ion-background-color);
}

.cover {
  border-radius: 8px;
}

.line-break {
  overflow: inherit;
  text-overflow: inherit;
  white-space: normal;
}

ion-item p {
  overflow: inherit;
  text-overflow: inherit;
  white-space: normal;
}

.popover-options {
  .popover-content {
    width: auto !important;
  }
}

.list-desktop {
  margin-right: calc((100% - 692px) / 2);
  margin-left: calc((100% - 692px) / 2);
}

img.smoothLoad {
  transition: opacity 0.5s;
  opacity: 0;
}

img.smoothFade {
  transition: opacity 0.5s;
  opacity: 1;
}

img.loaded {
  opacity: 1;
}

img.unloaded {
  transition: opacity 0.5s;
  opacity: 0;
}

.touchable {
  cursor: pointer;
}

a {
  text-decoration: none !important;
  color: var(--ion-color-primary) !important;
  cursor: pointer !important;
}

ion-back-button {
  color: var(--ion-color-primary) !important;
}

ion-header ion-button {
  color: var(--ion-color-primary) !important;
}

super-tabs super-tabs-toolbar {
  background-color: var(--ion-color-background) !important;
}

/*
super-tabs super-tab-button ion-label {
    color: var(--ion-color-medium) !important;
}

super-tabs super-tab-button ion-icon {
    color: var(--ion-color-medium) !important;
}

super-tabs super-tab-button ion-label {
    --ion-color-contrast: green !important;
}
*/

super-tabs {
  --st-base-color-inactive: var(--ion-color-medium) !important;
  --st-base-color-active: var(--ion-color-primary) !important;
  --st-icon-color-active: var(--ion-color-primary) !important;
  --st-indicator-color: var(--ion-color-primary-tint) !important;
}

super-tabs super-tab {
  padding: 10px !important;
}

super-tab-button ion-label {
  text-align: center;
}

super-tab-button {
  --st-label-color-inactive: var(--ion-text-color) !important;
  --st-label-color-active: var(--ion-color-primary) !important;
}

.verifiedIcon {
  position: relative;
}

.errorIconInput {
  font-size: 20px;
}

.readerModalSettingsLow {
  --max-height: 40%;
}

.readerModalSettingsHigh {
  --max-height: 60%;
}

.readerModalSettingsOffline {
  --max-height: 25%;
}

.imageCoverModalDesktop {
  --width: 500px;
  --height: 760px;
}

swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

swiper-slide.detail-slide {
  padding: 0 5px;
  border-radius: 10px;
  margin: 5px;
}

.swiper-slide-active.detail-slide {
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: 0 0 0 2px var(--ion-color-primary);
}

.easeInGlobal {
  animation-name: easeIn;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: ease-in;
  animation-delay: 0s;
}

.modal-newsletter-desktop {
  --width: 520px;
  --height: 360px;
  --ion-backdrop-opacity: 0.7;
}

.modal-newsletter-cordova {
  --width: 100%;
  --height: 100%;
  --ion-backdrop-opacity: 1.2;
}

.popover-family-mode-desktop {
  --width: 500px;
}
